import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import UserRequestsPage from './pages/requests-page/UserRequestsPage';
import ReviewsPage from './pages/reviews-page/ReviewsPage';
import LandingPage from './pages/landing-page/LandingPage';
import SignupPage from './pages/signup-page/SignupPage';
import SingleReviewPage from './pages/single-review-page/SingleReviewPage';
import CriteriaTable from './components/CriteriaTable';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <LandingPage/>
            },
            {
                path: "requests",
                element: <UserRequestsPage/>
            },
            {
                path: "single-review",
                element: <SingleReviewPage/>
            },
            {
                path: "reviews",
                element: <ReviewsPage/>
            },
            {
                path: "signup",
                element: <SignupPage/>
            }
        ]
    }
]);

root.render(
    <RouterProvider router={router}/>
);