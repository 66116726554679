import { createRef, useEffect, useRef, useState } from "react";
import { Button, Form, FormLabel, Modal, Spinner, Stack } from "react-bootstrap";
import CriteriaTable from "../../components/CriteriaTable";
import { getClient } from "../../noteworthy-api/ClientFactory";
import { CriteriaRating } from "../../noteworthy-api/objects/Review";
import { ICriteriaRow } from "../../noteworthy-api/NoteworthyApiClient";

const SingleReviewPage = () => {
    const [stage, set_stage] = useState("idle");
    const [review, set_review] = useState<CriteriaRating[]>([]);
    const [show_table, set_show_table] = useState(false);
    const [criteria, set_criteria] = useState<ICriteriaRow[]>([]);
    const input_ref = createRef<HTMLTextAreaElement>();
    

    const show_table_click = () => set_show_table(true);
    const set_criteria_wrapper = (new_criteria: ICriteriaRow[]) => {
        set_show_table(false);
        set_criteria(new_criteria);
    }

    const criteria_search = (criteria_number: number) => {
        return criteria.find(row => row.criteria_number === criteria_number);
    }

    useEffect(() => {
        if (input_ref.current === null) return;

        if (stage === "idle") return;
        else if (stage === "submitting") {
            const client = getClient();
            client.singleReview(input_ref.current.value, criteria.map(c => c.criteria_number))
            .then(response => set_review(response))
            .then(() => set_stage("idle"));
        }
    });

    return (
        <>
        <Modal
            show={show_table}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onHide={() => set_show_table(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Criteria Table
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CriteriaTable on_selection={set_criteria_wrapper}/>
            </Modal.Body>
        </Modal>
        <Stack direction="horizontal" style={{width: "auto", alignItems: "start"}} gap={1}>
            <div>
                <Form.Control
                    ref={input_ref}
                    as="textarea"
                    placeholder="Enter note here..."
                    style={{height: "50vh", width: "70vw", fontSize: "20px", resize: "none"}}
                />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Button 
                        style={{width: "150px"}} 
                        disabled={stage === "submitting"}
                        onClick={() => set_stage("submitting")}
                    >
                        Review Note
                    </Button>
                    <FormLabel>{"Selected criteria: " + ((criteria.length === 0) ? "none": criteria.map(row => row.criteria_number))}</FormLabel>
                    <Button disabled={stage === "submitting"} variant="success" style={{width: "150px"}} onClick={show_table_click}>Choose Criteria...</Button>
                </div>
            </div>
            <div style={{height: "90vh", width: "30vw", fontSize: "20px", borderStyle: "outset", borderWidth: "1px", overflow: "scroll", borderRadius: "10px"}}>
                {(stage === "submitting") 
                ? <Spinner />
                : <ul>
                    {
                        review.map(rating => {
                        return (
                            <>
                            <li>
                                {criteria_search(rating.criteria_number)?.title + ": " + rating.rating}
                            </li>
                            <ul>
                                <li>{rating.feedback}</li>
                            </ul>
                            </>
                        );
                    })}
                </ul>}
            </div>
        </Stack>
        </>
    );
}

export default SingleReviewPage;